.hero-section {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center;
    align-items: center;
    height: 70vh; /* 100% of the viewport height */
    background: #232323; /* Slightly lighter black for the hero section */
    color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
  }

  .hero-text {
    text-align: center;
    max-width: 800px; /* Maximum width of the text block */
    margin-bottom: 20px; /* Space before the button */
  }

  .hero-text h1 {
    font-size: 2.5em;
    color: #fff;
    margin-bottom: 0.5em;
  }

  .hero-text p {
    font-size: 1em;
    color: #aaa; /* Lighter grey for the paragraph */
    margin-bottom: 1em;
  }

  .demo-button {
    font-size: 1em;
    padding: 15px 30px;
    background: #673ab7; /* Deep purple */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase; /* Stylistic choice for CTA */
    font-weight: bold;
  }

  .demo-button:hover {
    background: #5e35b1; /* Slightly darker purple on hover */
  }